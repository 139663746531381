
import {computed, defineComponent, onMounted, ref, watch} from 'vue';

export default defineComponent({
  name: 'NavActions',
  props: {
    collapsed: Boolean,
    minHeight: String,
  },
  setup(props) {
    const originalHeight = ref<string | null>(null);
    const height = ref<string | null>(null);

    const navActions = ref<HTMLDivElement | null>(null);

    const unmounted = ref<boolean>(true);

    const collapsed = computed<boolean>(() => {
      const {collapsed} = props as NavActionsProps;
      return collapsed || false;
    });

    const style = computed(() => {
      return {
        height: height.value,
      };
    });

    const classes = computed<string[]>(() => {
      const cls = [];
      if (collapsed.value) cls.push('collapsed');
      if (unmounted.value) cls.push('unmounted');
      return cls;
    });

    const updateHeight = () => {
      if (!collapsed.value) {
        if (originalHeight.value === null) {
          if (!navActions.value) return;
          originalHeight.value = `calc(${window.getComputedStyle(navActions.value).height} - 1px)`;
        }
        height.value = originalHeight.value;
      } else {
        height.value = '0';
      }
    };

    const getHeight = () => {
      return height.value;
    };

    watch(collapsed, () => updateHeight());

    onMounted(() => {
      updateHeight();
      unmounted.value = false;
    });

    return {
      navActions,
      style,
      classes,
      getHeight,
    };
  },
});
