
import {defineComponent} from 'vue';
import ImgEmpty from '@/components/empty/ImgEmpty.vue';

export default defineComponent({
  name: 'Empty',
  components: {ImgEmpty},
  props: {
    description: {
      type: String,
      required: false,
      default: 'No Data Available'
    }
  },
  setup() {
    return {};
  },
});
